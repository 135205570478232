export function parseDate(value) {
  if (value instanceof Date || value == null) {
    return value;
  }
  value = new Date(value);
  return isNaN(value) ? undefined : value;
}

export function parseDecimal(value, precision = 2) {
  if (isEmpty(value)) {
    return undefined;
  }
  return isNumber(value) ? value : +parseFloat(value).toFixed(precision);
}

export function parseInteger(value) {
  if (isEmpty(value)) {
    return undefined;
  }
  // If we have a number, let's be sure to floor it, as Integer has no decimals
  return isNumber(value) ? Math.floor(value) : parseInt(value);
}

export function parseBoolean(value) {
  /* When considering a boolean, all we care about is if it has a truthy value... the string "false" should be
   * considered not to be true, as it's more than like we want to parse this to the boolean false */
  return !(isEmpty(value) || value === 0 || isString(value) && value.toLowerCase() === "false");
}

export function parseString(value) {
  if (isEmpty(value)) {
    return undefined;
  }
  return isString(value) ? value : String(value);
}

export function isString(value) {
  return typeof value === "string";
}

export function isNumber(value) {
  return typeof value === "number";
}

export function isBoolean(value) {
  return typeof value === "boolean";
}

export function isArray(value) {
  return Array.isArray(value);
}

export function isFunction(value) {
  return typeof value === "function";
}

export function isEmpty(value) {
  return value === null || value === undefined || value === "" || isNumber(value) && isNaN(value) || isArray(value) && value.length === 0;
}

export function percentChange(current, previous) {
  return previous ? (current - previous) / previous * 100 : 0;
}

/**
 * @param {*} lhs
 * @param {*} rhs
 * @param {1|-1} [identity=1]
 * If this is set to -1, then the sorting will be reversed... this is if you're doing descending sorting
 * @returns {number}
 */
export function commonSort(lhs, rhs, identity = 1) {
  if (lhs === rhs) {
    return 0;
  }
  return lhs < rhs ? -1 * identity : 1 * identity;
}

export function toYearDashMonth(date) {
  date = parseDate(date);
  const localDateString = date.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const dateArray = localDateString.split("/");
  const year = dateArray[2];
  const month = dateArray[0];
  return `${year}-${month}`;
}
