import TailwindColors from "ui/TailwindColors.js";
import deepmerge from "deepmerge";

export function useDefaultPieChart(config = {}) {
  const height = config.legend?.show === false ? 345 : 400;
  const donutSize = config.chart?.type === "donut" ? 70 : 100;
  // We don't want to merge this one
  const colors = config.colors || [TailwindColors.indigo["800"], TailwindColors.indigo["600"], TailwindColors.indigo["500"], TailwindColors.indigo["400"], TailwindColors.indigo["300"], TailwindColors.indigo["200"], TailwindColors.indigo["100"], TailwindColors.indigo["50"]];
  return deepmerge({
    colors,
    chart: {
      type: "pie",
      height: `${height}px`,
      dropShadow: {
        enabled: true,
        color: "#111",
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2,
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: `${donutSize}%`,
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      hover: {
        filter: "none",
      },
    },
    theme: {
      palette: "palette2",
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      fontFamily: "Roboto",
    },
    tooltip: {
      custom({ seriesIndex, w }) {
        return w.config.labels[seriesIndex];
      },
    },
  }, config);
}
