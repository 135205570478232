<template>
  <div class="mx-6 rounded-lg">
    <div
      class="flex justify-between m-2 panel"
    />

    <div class="grid grid-cols-3 pt-3 pb-3">
      <div class="col-span-1">
        Account
      </div>
      <div class="col-span-1 ml-2">
        Billed
      </div>
      <div class="col-span-1 ml-2">
        Paid
      </div>
    </div>

    <template
      v-for="(item, index) in category"
      :key="index"
    >
      <div
        class="grid grid-cols-3"
      >
        <div class="col-span-1 mt-2 h-10 text-sm text-gray-600">
          {{ item.code }}: {{ item.title }}
        </div>
        <div class="col-span-1 mt-2 h-10">
          <CoreFieldCurrency
            v-model="item.billed"
            placeholder="Enter amount"
            :is-disabled="isPreview"
          />
        </div>
        <div class="col-span-1 mt-2 h-10">
          <CoreFieldCurrency
            v-model="item.paid"
            placeholder="Enter amount"
            :is-disabled="isPreview"
          />
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import { computed } from "vue";
import FormStore from "store/FormStore.js";
import { CoreFieldCurrency, CoreFieldDisplay } from "@purepm/core-ui";

export default {
  name: "InputFormGLCodes",
  components: {
    CoreFieldCurrency,
    CoreFieldDisplay,
  },
  props: {
    category: Array,
  },
  setup() {
    const store = FormStore.inject();
    const formData = computed({
      get: () => store.getFormData.value,
    });
    const isPreview = computed({
      get: () => store.getIsPreview.value,
    });

    return {
      formData,
      isPreview,
    };
  },
};
</script>

<style scoped>
/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  overflow: hidden;
}
</style>
