<template>
  <div
    class="flex space-x-2"
  >
    <CoreFieldSelectMulti
      v-model="company"
      class="w-64"
      field-label="Brand"
      placeholder="Select Brand"
      :options="companyOptions"
      :disabled="!isPreview"
      @update:modelValue="getSnapshot"
    />
    <CoreFieldSelectMulti
      v-model="year"
      class="w-36"
      field-label="Year"
      placeholder="Select Year"
      :options="yearOptions"
      :disabled="!isPreview"
      @update:modelValue="getSnapshot"
    />
    <CoreFieldSelectMulti
      v-model="month"
      class="w-48"
      field-label="Month"
      placeholder="Select Month"
      :options="monthOptions"
      :disabled="!isPreview"
      @update:modelValue="getSnapshot"
    />
    <div
      class="mt-6"
    >
      <div
        v-if="!isPreview"
      >
        <CoreButton
          :text="'Cancel'"
          class="mr-2"
          version="link"
          @click="cancel"
        />
        <CoreButton
          :text="'Save'"
          :version="'primary'"
          @click="handleSubmit"
        />
      </div>
      <CoreButton
        v-if="isPreview"
        :version="applyButtonVersion"
        @click="toggle($event)"
      >
        <span>Edit</span>
      </CoreButton>
    </div>
  </div>
  <template v-if="notify">
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
      aria-live="assertive"
      class="flex fixed inset-0 items-end py-6 px-4 mt-8 pointer-events-none sm:items-start sm:p-6"
    >
      <div class="flex flex-col items-center space-y-4 w-full sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            class="overflow-hidden w-full max-w-sm bg-white rounded-lg ring-1 ring-black shadow-lg pointer-events-auto ring-opacity-/5"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-1 pt-0.5 ml-3 w-0">
                  <p class="text-sm font-medium text-gray-900">
                    {{ submitStatus }}
                  </p>
                </div>
                <div class="flex ml-4 shrink-0">
                  <button
                    class="inline-flex text-gray-400 hover:text-gray-500 bg-white rounded-md focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-none"
                    @click="handleClearNotification"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon
                      class="w-5 h-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </template>
</template>

<script>
import { CoreFieldSelectMulti, CoreButton, EnumsButtonVersions } from "@purepm/core-ui";
import FormStore from "store/FormStore.js";
import CompanyStore from "store/CompanyStore.js";
import { CheckCircleIcon, XIcon, PencilAltIcon } from "@heroicons/vue/solid";
import { computed, watch } from "vue";
import { isEmpty } from "shared/utilities.js";
import { ref } from "vue";
import UserStore from "ui/common/UserStore.js";

export default {
  name: "InputFormFilters",
  components: {
    CoreFieldSelectMulti,
    CoreButton,
    CheckCircleIcon,
    XIcon,
  },
  setup() {
    const icon = PencilAltIcon;
    const companyStore = CompanyStore.inject();
    const userStore = UserStore.inject();
    const store = FormStore.inject();
    const notify = ref(false);
    const submitStatus = ref("");
    companyStore.fetchCompanies();
    const editionBlocked = ref(false);
    const handleApplyFilters = async () => {
      store.getMonthlySnapshot();
    };
    const yearOptions = computed({
      get: () => store.getYearOptions.value,
    });
    const monthOptions = computed({
      get: () => store.getMonthOptions.value,
    });
    const showEdit = computed({
      get: () => store.getShowEdit.value,
    });
    const cancel = () => {
      store.cancel();
    };
    const edit = () => {
      store.edit();
    };
    const toggle = () => {
      store.setIsPreview(false);
    };
    const company = computed({
      get: () => store.getSelectedCompany.value,
      set: store.updateSelectedCompany,
    });

    const year = computed({
      get: () => store.getSelectedYear.value,
      set: store.updateSelectedYear,
    });
    const month = computed({
      get: () => store.getSelectedMonth.value,
      set: store.updateSelectedMonth,
    });
    const isPreview = computed({
      get: () => store.getIsPreview.value,
    });
    const isLoading = computed({
      get: () => companyStore.isLoading.value,
    });
    const applyButtonVersion = computed(() => {
      const disable = isEmpty(company.value) || isEmpty(year.value) || isEmpty(month.value) || !isPreview.value || isLoading.value;
      return disable ? EnumsButtonVersions.DISABLED : EnumsButtonVersions.PRIMARY;
    });
    const disableSaveBtn = computed({
      get: () => store.getDisableSaveBtn.value,
    });

    const applySaveButtonVersion = computed(() => {
      return disableSaveBtn.value ? EnumsButtonVersions.DISABLED : EnumsButtonVersions.PRIMARY;
    });

    const companyOptions = computed({
      get: () => companyStore.getAllCompanies.value,
    });

    async function handleSubmit() {
      try {
        const response = await store.submitForm(`${userStore.user.value.value.name}`);
        if (response && (response.status === 201 || response.status === 204)) {
          store.setBackupFormData();
          submitStatus.value = "Data Saved!";
          notify.value = true;
          store.setIsPreview(true);
          setTimeout(() => notify.value = false, 2200);
        } else {
          submitStatus.value = "Could not save data!";
          notify.value = true;
        }
      } catch (err) {
        submitStatus.value = "Could not save data!";
        notify.value = true;
      }
    }
    const handleClearNotification = () => {
      notify.value = !notify.value;
    };
    const getSnapshot = () => {
      // setTimeout allows for the year's clearing out of the selected month to occur before calling the method
      setTimeout(store.getMonthlySnapshot, 250);
    };
    watch(year, () => {
      month.value = null;
    });

    return {
      company,
      year,
      month,
      companyOptions,
      yearOptions,
      monthOptions,
      handleApplyFilters,
      isLoading,
      applyButtonVersion,
      isPreview,
      showEdit,
      cancel,
      edit,
      applySaveButtonVersion,
      toggle,
      editionBlocked,
      handleSubmit,
      notify,
      submitStatus,
      icon,
      handleClearNotification,
      getSnapshot,
    };
  },
};
</script>
