import { computed, inject, provide, reactive } from "vue";
import { BASE_API } from "ui/Constants.js";
import { Month } from "shared/enums/Month.js";
import axios from "axios";
import { isEmpty } from "shared/utilities.js";

const today = new Date();
const previousMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
let yearOfPreviousMonth = previousMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
yearOfPreviousMonth = {
  label: yearOfPreviousMonth.toString(),
  value: yearOfPreviousMonth,
};
const years = [];
for (let i = yearOfPreviousMonth.value; i >= 2018; i--) {
  years.push({
    value: i,
    label: String(i),
  });
}

const FormStore = {
  init: () => {
  // Form State
    const state = reactive({
      landingScreen: true,
      disableSaveBtn: true,
      showEdit: false,
      isPreview: true,
      isLoading: false,
      filterValues: {
        selectedCompany: null,
        selectedYear: yearOfPreviousMonth,
        selectedMonth: Month.options[previousMonth],
      },
      queriedValues: {
        selectedCompany: null,
        selectedYear: null,
        selectedMonth: null,
      },
      yearOptions: years,
      backupFormData: {},
      formData: {
        updatedBy: null,
        updatedOn: null,
        doorsTotal: null,
        doorsAdded: null,
        doorsRemoved: null,
        doorsUnderHOA: null,
        rentCollected: null,
        rentUnpaidZeroToThirty: null,
        rentUnpaidTotal: null,
        occupiedListed: null,
        occupiedUnlisted: null,
        occupiedTotal: null,
        vacantUnlisted: null,
        vacantListed: null,
        vacantTotal: null,
        evictionsInProcess: null,
        comments: null,
        revenueTotal: null,
        totalNewDoorsUnderHOA: null,
        totalLostDoorsUnderHOA: null,
        revenue: [
          {
            name: "managementFees",
            title: "Management Fees",
            code: 40100,
            billed: null,
            paid: null,
          },
          {
            name: "leasingFees",
            title: "Leasing/Admin Fees",
            code: 40200,
            billed: null,
            paid: null,
          },
          {
            name: "tenantFees",
            title: "Tenant Fees",
            code: 40300,
            billed: null,
            paid: null,
          },
          {
            name: "maintenanceFees",
            title: "Maintenance Fees",
            code: 40400,
            billed: null,
            paid: null,
          },
          {
            name: "applicationFee",
            title: "Application Fee",
            code: 40450,
            billed: null,
            paid: null,
          },
          {
            name: "renewalFees",
            title: "Renewal Fees",
            code: 40451,
            billed: null,
            paid: null,
          },
          {
            name: "brokerageFees",
            title: "Brokerage Fees",
            code: 40500,
            billed: null,
            paid: null,
          },
          {
            name: "commissions",
            title: "Commissions",
            code: 40525,
            billed: null,
            paid: null,
          },
          {
            name: "petInspectionFee",
            title: "Pet Inspection Fee",
            code: 40550,
            billed: null,
            paid: null,
          },
          {
            name: "miscIncome",
            title: "Misc. Income",
            code: 40600,
            billed: null,
            paid: null,
          },
          {
            name: "nsfLateFee",
            title: "NSF/Late Fee",
            code: 40650,
            billed: null,
            paid: null,
          },
        ],
      },
    });

    // Getters
    const getSelectedCompany = computed(() => state.filterValues.selectedCompany);
    const getSelectedYear = computed(() => state.filterValues.selectedYear);
    const getSelectedMonth = computed(() => state.filterValues.selectedMonth);
    const getFormData = computed(() => state.formData);
    const getDoorsTotal = computed(() => state.formData.doorsTotal);
    const getDisableSaveBtn = computed(() => state.disableSaveBtn);
    const getShowEdit = computed(() => JSON.stringify(state.filterValues) === JSON.stringify(state.queriedValues) && state.filterValues?.selectedCompany !== null && state.filterValues?.selectedMonth?.value !== null && state.filterValues?.selectedYear !== null);
    const getIsPreview = computed(() => state.isPreview);
    const getIsLoading = computed(() => state.isLoading);
    const getLandingScreen = computed(() => state.landingScreen);
    const getMonthOptions = computed(() => {
      const yearValue = parseInt(state.filterValues.selectedYear?.value);
      if (yearValue < yearOfPreviousMonth.value) {
        return Month.options;
      }
      if (yearValue === yearOfPreviousMonth.value) {
        return Month.options.filter((option) => {
          return option.value <= previousMonth;
        });
      }
      return [];
    });
    const getYearOptions = computed(() => {
      return state.yearOptions;
    });
    const getUpdatedBy = computed(() => {
      return state.formData.updatedBy;
    });
    const getUpdatedOn = computed(() => {
      return state.formData.updatedOn;
    });

    // Mutations
    const updateSelectedCompany = (company) => {
      state.filterValues.selectedCompany = company;
    };
    const updateSelectedYear = (year) => {
      state.filterValues.selectedYear = year;
    };
    const setDisableSaveBtn = (value) => {
      state.disableSaveBtn = value;
    };
    const updateSelectedMonth = (month) => {
      state.filterValues.selectedMonth = month;
    };
    const updateDoorsTotal = (doorsTotal) => {
      state.formData.doorsTotal = doorsTotal;
    };
    const updateFormDataKeyValue = (k, v) => {
      state.formData[k] = v;
    };
    const cancel = () => {
      state.isPreview = true;
      setFormData(Object.assign({}, state.backupFormData));
    };
    const edit = () => {
      state.isPreview = false;
    };
    const setIsLoading = (value) => {
      state.isLoading = value;
    };
    const setIsPreview = (value) => {
      state.isPreview = value;
    };
    const setFormData = (data) => {
      Object.keys(state.formData).forEach((key) => {
        if (key === "revenue") {
          state.formData.revenue.forEach((e) => {
            if (data.revenue) {
              const found = data.revenue.find((i) => e.code === i.code);
              if (found) {
                e.paid = found.paid;
                e.billed = found.billed;
              } else {
                e.paid = null;
                e.billed = null;
              }
            } else {
              e.paid = null;
              e.billed = null;
            }
          });
        } else {
          state.formData[key] = data[key];
          if (isNaN(data[key]) && !data[key]) {
            state.formData[key] = null;
          }
        }
      });
    };
    const setBackupFormData = (value) => {
      if (value) {
        state.backupFormData = value;
      } else {
        const backupRevenue = state.formData.revenue.map((revenue) => {
          return Object.assign({}, revenue);
        });
        state.backupFormData = Object.assign({}, state.formData);
        state.backupFormData.revenue = backupRevenue;
      }
    };
    const setQueriedValues = (value) => {
      state.queriedValues = Object.assign({}, value);
    };
    const setLandingScreen = (value) => {
      state.landingScreen = value;
    };

    // Actions
    const getMonthlySnapshot = async () => {
      const filters = state.filterValues;
      if (!getShowEdit.value && !isEmpty(filters.selectedYear) && !isEmpty(filters.selectedMonth) && !isEmpty(filters.selectedCompany)) {
        setIsLoading(true);
        await axios.get(`${BASE_API}/companies/${filters.selectedCompany?.value}/year/${filters.selectedYear?.value}/month/${filters.selectedMonth?.value + 1}`).then((response) => {
          if (response.status === 200) {
            setFormData(response.data);
            setQueriedValues(state.filterValues);
          }
        }).catch((err) => {
          if (err.response.status === 404) {
            const emptyFormData = {};
            Object.keys(state.formData).forEach((key) => {
              if (key !== "revenue") emptyFormData[key] = null;
            });
            emptyFormData.revenue = state.formData.revenue.map((revenue) => {
              const revenueObject = Object.assign({}, revenue);
              revenueObject.billed = null;
              revenueObject.paid = null;
              return revenueObject;
            });
            setFormData(emptyFormData);
            setQueriedValues(state.filterValues);
          }
        });
        setLandingScreen(false);
        setBackupFormData();
        setIsLoading(false);
        setDisableSaveBtn(false);
      }
    };

    const submitForm = (username) => {
      const payload = state.formData;
      payload.updatedBy = username;
      payload.updatedOn = Date.now();
      return axios.put(`${BASE_API}/companies/${state.filterValues.selectedCompany.value}/year/${state.filterValues.selectedYear.value}/month/${state.filterValues.selectedMonth.value + 1}`, payload);
    };

    provide("getSelectedCompany", getSelectedCompany);
    provide("getSelectedYear", getSelectedYear);
    provide("getSelectedMonth", getSelectedMonth);
    provide("updateSelectedCompany", updateSelectedCompany);
    provide("updateSelectedYear", updateSelectedYear);
    provide("updateSelectedMonth", updateSelectedMonth);
    provide("getFormData", getFormData);
    provide("getDoorsTotal", getDoorsTotal);
    provide("updateDoorsTotal", updateDoorsTotal);
    provide("updateFormDataKeyValue", updateFormDataKeyValue);
    provide("submitForm", submitForm);
    provide("getMonthlySnapshot", getMonthlySnapshot);
    provide("getDisableSaveBtn", getDisableSaveBtn);
    provide("getShowEdit", getShowEdit);
    provide("cancel", cancel);
    provide("edit", edit);
    provide("getIsPreview", getIsPreview);
    provide("getIsLoading", getIsLoading);
    provide("getMonthOptions", getMonthOptions);
    provide("getYearOptions", getYearOptions);
    provide("setIsPreview", setIsPreview);
    provide("setBackupFormData", setBackupFormData);
    provide("getUpdatedBy", getUpdatedBy);
    provide("getUpdatedOn", getUpdatedOn);
    provide("getLandingScreen", getLandingScreen);
  },

  inject: () => ({
    getSelectedCompany: inject("getSelectedCompany"),
    getSelectedYear: inject("getSelectedYear"),
    getSelectedMonth: inject("getSelectedMonth"),
    updateSelectedCompany: inject("updateSelectedCompany"),
    updateSelectedYear: inject("updateSelectedYear"),
    updateSelectedMonth: inject("updateSelectedMonth"),
    getFormData: inject("getFormData"),
    getDoorsTotal: inject("getDoorsTotal"),
    updateDoorsTotal: inject("updateDoorsTotal"),
    updateFormDataKeyValue: inject("updateFormDataKeyValue"),
    submitForm: inject("submitForm"),
    getMonthlySnapshot: inject("getMonthlySnapshot"),
    getDisableSaveBtn: inject("getDisableSaveBtn"),
    getShowEdit: inject("getShowEdit"),
    cancel: inject("cancel"),
    edit: inject("edit"),
    getIsPreview: inject("getIsPreview"),
    getIsLoading: inject("getIsLoading"),
    getMonthOptions: inject("getMonthOptions"),
    getYearOptions: inject("getYearOptions"),
    setIsPreview: inject("setIsPreview"),
    setBackupFormData: inject("setBackupFormData"),
    getUpdatedBy: inject("getUpdatedBy"),
    getUpdatedOn: inject("getUpdatedOn"),
    getLandingScreen: inject("getLandingScreen"),
  }),
};

export default FormStore;
