import { createApp } from "vue";
import "ui/index.css";
import App from "ui/App.vue";
import "shared/overrides/index.js";
import CoreUI from "@purepm/core-ui/dist/CoreUI.js";
import router from "./router";
import { createAuth0 } from "@auth0/auth0-vue";

const app = createApp(App);
app.config.unwrapInjectedRef = true;
app.use(CoreUI);
app.use(router);
app.use(
  // Audience is the default and can be set at the getToken level if they need to change
  createAuth0({
    domain: import.meta.env.VITE_AUTH_ID,
    client_id: import.meta.env.VITE_CLIENT_ID,
    redirect_uri: `${window.location.origin}`,
    useRefreshTokens: true,
    audience: import.meta.env.VITE_AUDIENCE,
    scope: "view", // TODO: Make env var
  }),
);
app.mount("#app");
