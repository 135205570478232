<template>
  <div class="flex space-x-2">
    <CoreFieldSelectMulti
      v-model="selectedCompanies"
      class="mx-12 max-w-sm"
      :options="allCompanies"
      field-label="Brands"
      :placeholder="brandMultiSelectPlaceholder"
      :collapsible="false"
      :multiple="true"
      entities-select
    />
    <CoreFieldSelectMulti
      v-model="selectedAggregateType"
      class="w-32"
      :options="aggregateOptions"
      placeholder="Select Aggregate Type"
      field-label="Aggregate by"
    />
    <CoreFieldSelectMulti
      v-model="selectedYear"
      class="w-24"
      :options="yearOptions"
      placeholder="Select Year"
      field-label="Year"
    />
    <CoreFieldSelectMulti
      v-if="isQuarterly"
      v-model="selectedQuarter"
      class="w-40"
      :options="quarterOptions"
      placeholder="Select Quarter"
      field-label="Quarter"
    />
    <CoreFieldSelectMulti
      v-if="isMonthly"
      v-model="selectedMonth"
      class="w-40"
      :options="monthOptions"
      placeholder="Select Month"
      field-label="Month"
    />
    <CoreButton
      text="Apply"
      class="mt-6 h-9"
      :version="applyButtonVersion"
      @click="onClickApplyBtn"
    />
  </div>
</template>

<script>
import { Month } from "shared/enums/Month.js";
import { Quarter } from "shared/enums/Quarter.js";
import { computed, ref, unref, watch } from "vue";
import { isEmpty } from "shared/utilities.js";
import { CoreButton, CoreFieldSelectMulti, EnumsButtonVersions } from "@purepm/core-ui";
import { useMultiSelectOptions } from "ui/composables/multiSelect.js";
import { AggregateType } from "shared/enums/AggregateType.js";
import CompanyStore from "store/CompanyStore.js";

const today = new Date();
const previousMonth = today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1;
const yearOfPreviousMonth = previousMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();

const yearOptions = [];
for (let i = yearOfPreviousMonth; i >= 2018; i--) {
  yearOptions.push({
    value: i,
    label: String(i),
  });
}
export default {
  name: "ContainerDashboardFilters",
  components: {
    CoreFieldSelectMulti,
    CoreButton,
  },
  emits: ["change:filter"],
  setup(props, { emit }) {
    const store = CompanyStore.inject();
    store.fetchCompanies();
    const year = ref(yearOfPreviousMonth);
    const month = ref(previousMonth);
    const quarter = ref(null);
    const allCompanies = computed({
      get: () => store.getAllCompanies.value,
    });
    const aggregateType = ref(AggregateType.Monthly);
    const selectedCompanies = ref([]);
    const isQuarterly = computed(() => aggregateType.value === AggregateType.Quarterly);
    const isMonthly = computed(() => aggregateType.value === AggregateType.Monthly);
    const quarterOptions = computed(() => {
      const selectedYear = parseInt(year.value);
      if (selectedYear < yearOfPreviousMonth) {
        return Quarter.options;
      }
      if (selectedYear === yearOfPreviousMonth) {
        let spliceAmount = 4;
        if (previousMonth < 3) {
          spliceAmount = 1;
        } else if (previousMonth < 6) {
          spliceAmount = 2;
        } else if (previousMonth < 9) {
          spliceAmount = 3;
        }
        return Quarter.options.splice(0, spliceAmount);
      }
      return [];
    });
    const monthOptions = computed(() => {
      const selectedYearValue = parseInt(year.value);
      if (selectedYearValue < yearOfPreviousMonth) {
        return Month.options;
      }
      if (selectedYearValue === yearOfPreviousMonth) {
        return Month.options.filter((option) => {
          return option.value <= previousMonth;
        });
      }
      return [];
    });
    const brandMultiSelectPlaceholder = computed(() => selectedCompanies.value.length === 0 ? "All Brands" : "Select Brands");

    const aggregateOptions = AggregateType.options;
    const selectedAggregateType = useMultiSelectOptions(aggregateOptions, aggregateType);
    function applyFilter() {
      let date;
      const quarterValue = unref(quarter);
      const monthValue = unref(month);
      const yearValue = unref(year);
      if (!isEmpty(quarterValue)) {
        date = Date.parseQuarter({
          quarter: quarterValue,
          year: yearValue,
        });
      } else if (!isEmpty(monthValue)) {
        date = new Date(yearValue, monthValue);
      } else {
        date = new Date(yearValue, 0);
      }
      emit("change:filter", {
        date: date.toMonthDayYear(),
        companies: selectedCompanies.value.map(({ value }) => value),
        aggregateType: unref(aggregateType),
      });
    }
    function onClickApplyBtn() {
      applyFilter();
    }
    const applyButtonVersion = computed(() => {
      const disable = isEmpty(aggregateType) || isEmpty(year.value) || isQuarterly.value && isEmpty(quarter.value) || isMonthly.value && isEmpty(month.value);
      return disable ? EnumsButtonVersions.DISABLED : EnumsButtonVersions.PRIMARY;
    });
    // Whenever the aggregate type changes, let's clear out the selected values for month and quarter
    watch(selectedAggregateType, () => {
      month.value = null;
      quarter.value = null;
    });
    watch(year, () => {
      month.value = null;
      quarter.value = null;
    });
    return {
      allCompanies,
      yearOptions,
      monthOptions,
      quarterOptions,
      selectedCompanies,
      aggregateOptions,
      onClickApplyBtn,
      applyButtonVersion,
      isQuarterly,
      isMonthly,
      selectedAggregateType,
      selectedMonth: useMultiSelectOptions(Month.options, month),
      selectedYear: useMultiSelectOptions(yearOptions, year),
      selectedQuarter: useMultiSelectOptions(Quarter.options, quarter),
      fetchingCompanies: store.isLoading.value,
      brandMultiSelectPlaceholder: brandMultiSelectPlaceholder,
    };
  },
};
</script>
