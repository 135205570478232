<template>
  <div>
    <apexchart
      height="400"
      type="bar"
      class="py-3 pr-3 pb-8 bg-white rounded-br-lg"
      :options="options.chartOptions"
      :series="options.data"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { AggregateType } from "shared/enums/AggregateType.js";
import TailwindColors from "ui/TailwindColors.js";
import { createChartDateLabel } from "../../utilities/labelsHelper";

const titleKeys = AggregateType.options;
export default {
  name: "ChartNewAndLostDoors",
  components: {
    apexchart: VueApexCharts,
  },
  inject: ["revenueRecord", "dataFilters"],
  computed: {
    options() {
      const newDoorsSeries = [];
      const lostDoorsSeries = [];
      const labels = [];
      const { aggregateType = AggregateType.Monthly } = this.dataFilters || {};
      const chartTitle = titleKeys[aggregateType].label + " trend";
      this.revenueRecord?.summary.revenueHistory.forEach((period) => {
        const {
          doorsAdded, doorsRemoved, date,
        } = period;
        newDoorsSeries.push(doorsAdded);
        lostDoorsSeries.push(doorsRemoved);
        createChartDateLabel(labels, aggregateType, date);
      });
      return {
        data: [
          {
            name: "New Doors",
            data: newDoorsSeries,
          },
          {
            name: "Lost Doors",
            data: lostDoorsSeries,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: true,
              offsetX: -12,
              offsetY: 16,
              tools: {
                pan: false,
                selection: false,
                zoom: false,
                zoomout: false,
                reset: false,
                zoomin: false,
              },
            },
          },
          colors: [TailwindColors.teal["600"], TailwindColors.red["400"]],
          title: {
            text: chartTitle,
            offsetX: 8,
            offsetY: 12,
            margin: 14,
            style: {
              fontFamily: "Roboto",
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: true,
            shared: false,
            style: {
              fontFamily: "Roboto",
            },
            x: {
              show: false,
            },

          },
          legend: {
            labels: {
              useSeriesColors: true,
              fontSize: "10px",
            },
            fontFamily: "Roboto",
            position: "bottom",
            horizontalAlign: "left",
            itemMargin: {
              vertical: 20,
            },
            markers: {
              radius: 50,
            },
          },
          labels,
          xaxis: {
            catergories: labels,
            labels: {
              style: {
                fontSize: "0.50vw",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: TailwindColors.teal["500"],
              },
              formatter: function(val) {
                return val.toNumber();
              },
            },
            tickAmount: 8,
          },
        },
      };
    },
  },
};
</script>
