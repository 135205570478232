<template>
  <div class="flex flex-row gap-2 py-4 pl-9 bg-indigo-800 split">
    <div class="wash">
      <img
        class="h-16"
        src="/img/pure-p-logo.png"
        alt="Company Logo"
      >
    </div>
    <CoreTabs
      v-model="currentTab"
      :tabs="tabs"
      custom-tab-cls="ml-8 mt-4 pb-1"
      class="no-border tab-text-white"
      @change="changeURL"
    />
    <CoreUserBadge />
  </div>
</template>

<script>
import CoreUserBadge from "common/CoreUserBadge.vue";
import { CoreTabs } from "@purepm/core-ui";

const TABS = [
  {
    name: "Financial Dashboard",
    value: "Home",
  },
  {
    name: "Financial Input Form",
    value: "FinancialForm",
  },
];

const INIT_TAB = TABS[0];

export default {
  name: "BasicHeader",
  components: {
    CoreUserBadge,
    CoreTabs,
  },
  data() {
    return {
      tabs: TABS,
      currentTab: INIT_TAB,
    };
  },
  methods: {
    changeURL(e) {
      this.currentTab = e;
      e.value === "FinancialForm" ? this.$router.push({
        name: "FinancialForm",
      }) : this.$router.push({
        name: "Home",
      });
    },
  },
  watch: {
    "$route.name": function(newName) {
      if (newName === "FinancialForm") {
        this.currentTab = TABS[1];
      }
    },
  },
};
</script>

<style>
/*  NOTE:  "scoped" has been removed from style tag to enable modifying css from deep within a core component
*/
.split {
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top:0;
  z-index:10;
  height:60px;
}
.results-box {
  min-width: 533px;
  max-height: 80vh;
}
.wash {
  background-color:white;
}

/* the next two css properties are altering a css property that is nested deep within the CoreTabs component.  Th CoreTab component comes with gray text and a bottom border baked in.  These two properties override that styling */
.no-border > div > div {
  border: none;
}
.tab-text-white > div > div > nav > a {
  color: white;
}
</style>
