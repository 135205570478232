<template>
  <div class="input-form-container">
    <CoreCard
      v-if="!showEdit"
    >
      <template
        #body
      >
        <div class="flex flex-col justify-center items-center">
          <div>
            <img
              v-if="isLandingScreen"
              :src="LandingScreen"
              class="landing-screen"
              alt="Landing Screen"
            >
          </div>
          <CoreText
            v-if="isLandingScreen"
            value="Begin compiling your data by selecting your brand above."
            class="text-lg text-gray-800"
          />
          <img
            v-else
            src="/img/LoadingScreen.svg"
            alt="Loading Screen"
          >
        </div>
      </template>
    </CoreCard>
    <CoreCard v-else>
      <template #body>
        <div class="flex justify-center">
          <div
            v-show="isLoading"
            class="flex absolute z-10 flex-col items-center place-content-center w-full h-full"
          >
            <div class="absolute w-full h-full bg-gray-300 opacity-60" />
            <CoreLoadingBar class="w-28 h-2" />
          </div>
        </div>
        <div
          class="flex"
        >
          <div
            class="w-1/2 right-border-one-pixel"
          >
            <CoreText
              value="Doors"
              class="ml-6 text-lg font-medium"
            />
            <div
              class="form-section"
            >
              <FRPInputFieldWithTooltip
                value="doorsTotal"
                :locked="isPreview"
                label="Total Doors"
                :type="'integer'"
              >
                Total managed doors at month end. This should NOT include HOA doors if your brand manages HOA.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="doorsAdded"
                :locked="isPreview"
                label="Total New Doors"
                :type="'integer'"
              >
                Total number of new doors at month end. This should NOT include HOA doors OR “RENT/LEASE Only” or dummy/test accounts.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="doorsRemoved"
                :locked="isPreview"
                label="Total Lost Doors"
                :type="'integer'"
              >
                Total number of deactivated properties at month end. Deactivated means that you no longer manage the property and it has been removed from your property count. <br> <br> All work has been completed and the account is closed out. This should NOT include HOA doors OR “RENT/LEASE Only” or dummy/test accounts.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="doorsUnderHOA"
                :locked="isPreview"
                label="Total Doors Under HOA Management"
                :type="'integer'"
              >
                HOA Management ONLY. Total number doors under HOA management at month end.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="totalNewDoorsUnderHOA"
                :locked="isPreview"
                label="Total New Doors Under HOA Management"
                :type="'integer'"
              >
                HOA Management ONLY - Total number of NEW doors under HOA management. Please include the City they are located in in COMMENT box at bottom of input form.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="totalLostDoorsUnderHOA"
                :locked="isPreview"
                label="Total Lost Doors Under HOA Management"
                :type="'integer'"
              >
                HOA Management ONLY - Total number of LOST doors under HOA management.
              </FRPInputFieldWithTooltip>
            </div>
            <CoreText
              value="Rent"
              class="ml-6 text-lg font-medium"
            />
            <div class="form-section">
              <FRPInputFieldWithTooltip
                value="rentCollected"
                :locked="isPreview"
                label="Rent Collected"
                :type="'currency'"
              >
                Total amount of rent collected at month end (for the last 30 days). This should NOT include HOA doors if your company manages HOA.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="rentUnpaidZeroToThirty"
                :locked="isPreview"
                label="Total unpaid rent (0-30)"
                :type="'currency'"
              >
                Total amount of unpaid rent over the last 30 days (Rent Only). Total amount of unpaid rent over the last 0-30 days.This should NOT include HOA income if your company manages HOA.
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="rentUnpaidTotal"
                :locked="isPreview"
                label="Total amount of unpaid rent"
                :type="'currency'"
              >
                Total amount of unpaid rent (rent only). This is the total amount of all unpaid rent on all active properties (includes the last 30 days). This should NOT include HOA income if your company manages HOA.
              </FRPInputFieldWithTooltip>
            </div>
            <CoreText
              value="Occupancy"
              class="ml-6 text-lg font-medium"
            />
            <div class="form-section">
              <FRPInputFieldWithTooltip
                value="occupiedListed"
                :locked="isPreview"
                label="Occupied listed units"
                :type="'integer'"
              >
                Total occupied properties at month end that are LISTED. This should NOT include HOA doors OR “RENT/LEASE Only.”
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="occupiedUnlisted"
                :locked="isPreview"
                label="Occupied unlisted units"
                :type="'integer'"
              >
                Total occupied properties at month end that are NOT listed. This should NOT include HOA doors OR “RENT/LEASE Only.”
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="occupiedTotal"
                :locked="isPreview"
                label="Total occupied units"
                :type="'integer'"
              >
                Total occupied properties at month end. This should NOT include HOA doors OR “RENT/LEASE Only.”
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="vacantListed"
                :locked="isPreview"
                label="Vacant listed units"
                :type="'integer'"
              >
                Total number of vacant properties at month end that are LISTED for rent. <br> **This should NOT include HOA doors OR 'RENT/LEASE Only' or dummy/test accounts**
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="vacantUnlisted"
                :locked="isPreview"
                label="Vacant unlisted units"
                :type="'integer'"
              >
                Total number of vacant properties at month end that are UNLISTED. Vacant properties that are not listed due to reasons such as pending move-in, waiting to be deactivated, vacant due to turnover work, etc. <br>  **This should NOT include HOA doors OR 'RENT/LEASE Only' or dummy/test accounts**
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="vacantTotal"
                :locked="isPreview"
                label="Total vacant units"
                :type="'integer'"
              >
                Total number of vacant properties at month end. Vacant properties should be vacant properties listed for rent, pending move-in, waiting to be deactivated, or vacant due to turnover work. <br> **This should NOT include HOA doors OR 'RENT/LEASE Only' or dummy/test accounts.**
              </FRPInputFieldWithTooltip>
              <FRPInputFieldWithTooltip
                value="evictionsInProcess"
                :locked="isPreview"
                label="Evictions in process"
                :type="'integer'"
              >
                Number of evictions in progress at month end.
              </FRPInputFieldWithTooltip>
            </div>
            <FRPInputFieldWithTooltip
              value="comments"
              :locked="isPreview"
              label="Comments"
              :type="'textArea'"
              class="ml-6 w-2/3"
            >
              Tell us any reasons for large increases or decreases in door count. If applicable, please tell us the city any new HOA doors are located in.
            </FRPInputFieldWithTooltip>
          </div>
          <div class="w-1/2">
            <CoreText
              value="Revenue"
              class="ml-6 text-lg font-medium"
            />
            <div class="mt-4 ml-6 w-1/2">
              <div class="total-revenue">
                <CoreText
                  value="Total Revenue"
                  class="text-sm font-normal text-gray-600"
                />
              </div>
              <CoreFieldCurrency
                v-model="formData.revenueTotal"
                placeholder="Enter amount"
                :is-disabled="isPreview"
              />
            </div>
            <div class="my-4 ml-6">
              <CoreText
                value="Revenue By Account"
                class="text-lg font-medium"
              />
            </div>
            <div class="overflow-y-auto">
              <InputFormGLCodes :category="formData.revenue" />
            </div>
          </div>
        </div>
      </template>
    </CoreCard>
  </div>
</template>

<script>
import { CoreCard,
  CoreText,
  CoreFieldCurrency,
  CoreButton, CoreLoadingBar, EnumsButtonVersions } from "@purepm/core-ui";
import { CheckCircleIcon, XIcon } from "@heroicons/vue/solid";
import { computed } from "vue";
import FormStore from "ui/store/FormStore.js";
import CompanyStore from "ui/store/CompanyStore.js";
import InputFormGLCodes from "ui/components/InputFormGLCodes.vue";
import FRPInputFieldWithTooltip from "ui/components/FRPInputFieldWithTooltip.vue";
import LandingScreen from "ui/assets/img/LandingScreen.svg";

export default {
  name: "InputForm",
  components: {
    FRPInputFieldWithTooltip,
    InputFormGLCodes,
    CoreCard,
    CoreText,
    CoreFieldCurrency,
    CoreButton,
    CheckCircleIcon,
    XIcon,
    CoreLoadingBar,
  },
  setup() {
    const store = FormStore.inject();
    const companyStore = CompanyStore.inject();

    const isPreview = computed({
      get: () => store.getIsPreview.value,
    });
    const showEdit = computed({
      get: () => store.getShowEdit.value,
    });
    const isLoading = computed({
      get: () => store.getIsLoading.value,
    });
    const isLandingScreen = computed({
      get: () => store.getLandingScreen.value,
    });

    const cancelButtonVersion = computed(() => EnumsButtonVersions.TRANSPARENT_WITH_BACKGROUND);

    const formData = computed({
      get: () => store.getFormData.value,
    });

    companyStore.fetchCompanies();
    return {
      formData,
      cancelButtonVersion,
      companyStore,
      isPreview,
      showEdit,
      isLoading,
      LandingScreen,
      isLandingScreen,
    };
  },
};
</script>

<style scoped>
.form-section {
  @apply gap-6 py-4 px-6 sm:grid sm:grid-cols-1 lg:grid lg:grid-cols-3 flex flex-col;
}
.flag-border {
    border-left-color: #2A6CF9;
    border-left-width: 10px;
}
.right-border-one-pixel {
  border-right-color: #DDE1E7;
  border-right-width: 1px;
}
.landing-screen {
  height: 350px;
}
.input-form-container {
  height: calc(100vh - 60px);
}
.total-revenue {
  margin-bottom: 3px;
}
</style>
