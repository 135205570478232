<template>
  <div class="col-span-12 overflow">
    <div class="flex relative text-sm text-gray-800">
      <section class="flex flex-col flex-1 p-8 bg-indigo-50">
        <div class="flex justify-between">
          <Welcome />
          <ContainerDashboardFilters
            class="flex justify-end"
            @change:filter="onChangeFilter"
          />
        </div>
        <div
          v-show="!dashboardLoading"
          class="main-content"
        >
          <ContainerSummaries />
          <ContainerCompanies />
          <ContainerCategories />
          <TableDashboardRevenueHistory />
        </div>
        <div
          v-show="dashboardLoading"
          class="flex m-auto"
        >
          <img
            src="/img/DashboardLoading.svg"
            alt="Loading"
            class="mt-4 animate-pulse"
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { RevenueModel } from "ui/models/RevenueModel.js";
import { provide, ref } from "vue";
import ContainerCompanies from "ui/components/ContainerCompanies.vue";
import ContainerDashboardFilters from "ui/components/ContainerDashboardFilters.vue";
import ContainerCategories from "ui/components/ContainerCategories.vue";
import ContainerSummaries from "ui/components/ContainerSummaries.vue";
import Welcome from "ui/components/Welcome.vue";
import TableDashboardRevenueHistory from "ui/components/TableDashboardRevenueHistory.vue";
import CompanyStore from "store/CompanyStore.js";
import DashboardStore from "store/DashboardStore.js";

export default {
  name: "Home",
  components: {
    ContainerCategories,
    ContainerDashboardFilters,
    ContainerCompanies,
    ContainerSummaries,
    Welcome,
    TableDashboardRevenueHistory,
  },
  setup() {
    const revenueRecord = ref(null);
    const dataFilters = ref(null);
    const loading = ref(false);
    const companyStore = CompanyStore.inject();
    const dashboardStore = DashboardStore.inject();
    provide("revenueRecord", revenueRecord);
    provide("dataFilters", dataFilters);
    async function onChangeFilter(filter) {
      dashboardStore.updateSelectedCompanies(filter.companies);
      dashboardStore.updateAggregateType(filter.aggregateType);
      dashboardStore.updateDate(filter.date);
      dashboardStore.getTableData();
      try {
        loading.value = true;
        revenueRecord.value = await RevenueModel.load(filter);
        dataFilters.value = filter;
      } catch (ex) {
        loading.value = false;
        console.error(ex);
      }
      loading.value = false;
    }
    return {
      onChangeFilter,
      loading,
      companyStore,
      dashboardLoading: dashboardStore.loadingDashboard,
    };
  },
  async mounted() {
    await this.companyStore.fetchCompanies();
    // set previous month
    const today = new Date();
    const previousMonth = today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1;
    const yearOfPreviousMonth = previousMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
    this.onChangeFilter({
      aggregateType: 0,
      companies: [],
      date: new Date(`${previousMonth + 1}/01/${yearOfPreviousMonth}`),
    });
  },
};
</script>

<style>
.main-content {
  @apply grid gap-4;
  grid-template-rows: repeat(3, auto);
}


</style>
